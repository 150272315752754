import { environment } from '@core/environment';
import { useAuth0Interceptor, GrpcRestFetchTransport } from '@deepup/api-utils';
import { inetworkClientApi } from '@deepup/internal-apis';
import { PointElementMaterial } from '@deepup/internal-apis/dist/typescript/network/v1/inetwork';
import { Autocomplete, TextField, ListItem, Grid, ListItemText, Typography } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

/** Default materials for NVt and Muffe.
 * In the future this can be moved to a networkd */
const DEFAULT_MATERIALS = ['GH_OB', 'MU_FB'];

export default function MaterialSelectAutocomplete({
  subType,
  updateProp,
  currentMaterialId,
  required = false,
}: {
  subType: string;
  updateProp: (key: string, value?: string) => void;
  currentMaterialId?: string;
  required?: boolean;
}) {
  const { projectId } = useParams();

  const [materials, setMaterials] = useState<PointElementMaterial[]>([]);

  const [material, setMaterial] = useState<PointElementMaterial | null>(null);

  const authInterceptor = useAuth0Interceptor();
  const client = useMemo(() => {
    const transportRest = new GrpcRestFetchTransport({
      baseUrl: environment.unifiedApiUrl,
      fetchInit: {
        credentials: 'include',
      },
      interceptors: [authInterceptor],
    });

    return new inetworkClientApi.InternalNetworkServiceClient(transportRest);
  }, [authInterceptor]);

  useEffect(() => {
    const fetchMaterials = async () => {
      if (!projectId) {
        return [];
      }
      const fetchedMaterials = await client.listPointElementMaterials({ projectId, subType })
        .response;
      setMaterials(fetchedMaterials.items);
    };
    fetchMaterials();
  }, [subType]);

  /** The material field value should always fallback to the default value
   * to prevent mapping issue in the magenta push workflow.
   * User can explicitely change the value, but it's not required. */
  useEffect(() => {
    if (!materials.length) return;

    const currentMaterial = materials.find((m) => m.id === currentMaterialId);
    const defaultMaterial = materials.find((m) => DEFAULT_MATERIALS.includes(m.id)) ?? null;
    const newMaterial = currentMaterial ?? defaultMaterial;
    setMaterial(newMaterial);
    updateProp('materialId', newMaterial?.id);
  }, [currentMaterialId, materials]);

  return (
    <Autocomplete
      options={materials}
      disabled={materials.length === 0}
      autoHighlight
      getOptionLabel={({ name, manufacturer, group, id }) =>
        `${id} | ${name} | ${group} ${manufacturer ? '|' : ''} ${manufacturer}`
      }
      componentsProps={{ paper: { elevation: 24 } }}
      onChange={(_, value) => {
        setMaterial(value);
        updateProp('materialId', value?.id);
      }}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Material"
          required={required}
          disabled={materials.length === 0}
        />
      )}
      renderOption={(props, option) => (
        <ListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={option.id}
          disableGutters
        >
          <Grid item xs={12} flexGrow={1}>
            <ListItemText>{option.id}</ListItemText>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {option.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {option.group}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="secondary">
              {option.manufacturer}
            </Typography>
          </Grid>
        </ListItem>
      )}
      value={material}
    />
  );
}
